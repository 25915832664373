import { Enums, DateMonthModes } from '../../_shared/enums/enums';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../_shared/models/models-index';
import { Filter, FilterConfigReport, FilterType } from '../../_shared/filter/filter.model';
import { ChatHelperService } from '../../provider-tools/provider-tools-index';
import * as constants from '../../_shared/constants/constants';
export class FilterConfig implements IFilterConfig {
    NUMBER_OF_MONTHS_TO_SHOW_DEFAULT = environment.numberOfMonthsToShowDefault;
    NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT = environment.numberOfQuartersToShowDefault;
    dateModeOptions = [
        Enums.dateModes.currentMonth,
        Enums.dateModes.lastMonth,
        Enums.dateModes.lastThirtyDays,
        Enums.dateModes.previousThreeMonths,
        Enums.dateModes.previousTwelveMonths
    ]
    showOrgCodeInFilter = false;
    showDealerCodeInFilter = false;
    showEntireOrgHierarchyForAllUsers = false;
    defaultMonthDateMode: DateMonthModes = 'calendar';
    filterTypeLookup: { [id: string]: FilterType } = {
        'org': { displayName: 'Hierarchy', name: 'org' },
        'campaign-type': { displayName: 'Campaign Type', name: 'campaign-type' },
        'channel': { displayName: 'Channel', name: 'channel' },
        'digad-channel': { displayName: 'Channel', name: 'digad-channel' },
        'chat-provider': { displayName: 'Provider', name: 'chat-provider' },
        'device-type': { displayName: 'Device Type', name: 'device-type' },
        'digad-provider': { displayName: 'Provider', name: 'digad-provider' },
        'digital-retailing-provider': { displayName: 'Provider', name: 'digital-retailing-provider' },
        'lead-type': { displayName: 'Lead Type', name: 'lead-type' },
        'profit-center': { displayName: 'Profit Center', name: 'profit-center' },
        'sale-type': { displayName: 'Sale Type', name: 'sale-type' },
        'source-type': { displayName: 'Source Type', name: 'source-type' },
        'trade-in-provider': { displayName: 'Provider', name: 'trade-in-provider' },
        'vehicle': { displayName: 'Vehicle', name: 'vehicle' },
        'vehicle-make': { displayName: 'Make', name: 'vehicle-make' },
        'vehicle-model': { displayName: 'Models', name: 'vehicle-model' },
        'website-provider': { displayName: 'Provider', name: 'website-provider' },
    };

    filterReportConfigs: { [id: string]: FilterConfigReport } = {
        // Digital Retailing Overview
        'DigitalRetailingOverview': {
            reportName: constants.reportNames.digitalRetailingOverview,
            dateMode: Enums.dateModes.currentMonth,
            filters: ['org', 'digital-retailing-provider', 'device-type'],
            defaults: ['org'],
            section: 'provider-tools',
            orgTypes: [1],
            salesCalendarToggleDisabled: true
        },
        // Chat Overview
        'ChatOverview': {
            reportName: constants.reportNames.chatOverview,
            dateMode: Enums.dateModes.currentMonth,
            filters: ['org', 'chat-provider', 'device-type'],
            defaults: ['org'],
            section: 'provider-tools',
            orgTypes: [1],
            salesCalendarToggleDisabled: true
        },
        'ChatProviderScorecard': {
            reportName: constants.reportNames.chatProviderScorecard,
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            filters: ['org'],
            defaults: ['org'],
            section: 'provider-tools',
            orgTypes: [1],
            salesCalendarToggleDisabled: true
        },
        // Trade-in Overview
        'TradeInOverview': {
            reportName: constants.reportNames.tradeInOverview,
            dateMode: Enums.dateModes.currentMonth,
            filters: ['org', 'trade-in-provider', 'device-type'],
            defaults: ['org'],
            section: 'provider-tools',
            orgTypes: [1],
            salesCalendarToggleDisabled: true
        },
        'TradeInProviderScorecard': {
            reportName: constants.reportNames.tradeInProviderScorecard,
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            filters: ['org'],
            defaults: ['org'],
            section: 'provider-tools',
            orgTypes: [1],
            salesCalendarToggleDisabled: true
        },
        // website
        'WebsiteOverview': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteOverview,
            filters: [
                'org', 'channel', 'website-provider', 'device-type'
            ],
            defaults: ['org'],
            section: 'website',
            orgTypes: [1],
            salesCalendarToggleDisabled: true
        },
        //Dig Ad
        'DigitalAdvertisingOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: false,
            reportName: constants.reportNames.digitalAdvertisingOverview,
            filters: ['org', 'digad-channel', 'profit-center', 'campaign-type', 'digad-provider', 'vehicle-model'],
            defaults: ['org', 'digad-channel', 'profit-center'],
            section: 'digital-advertising',
            orgTypes: [1],
        },
    };

    FILTER_CONFIG: { [id: string]: Filter } = {
        'org': {
            type: 'org',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'vehicle': {
            type: 'vehicle',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'chat-provider': {
            type: 'chat-provider',
            selected: [],
            removable: false,
            lockable: true,
            locked: false
        },
        'device-type': {
            type: 'device-type',
            selected: [
                { value: 1, display: 'Desktop' },
                { value: 2, display: 'Tablet' },
                { value: 3, display: 'Phone' },
                { value: 4, display: 'Other' }
            ],
            removable: false,
            lockable: true,
            locked: false
        },
        'lead-type': {
            type: 'lead-type',
            selected: [{ value: 1, display: 'New Sales' }],
            removable: false,
            lockable: true,
            locked: false
        },
        'sale-type': {
            type: 'sale-type',
            selected: [{ value: 1, display: 'New' }],
            removable: false,
            lockable: true,
            locked: false
        },
        'source-type': {
            type: 'source-type',
            available: [1, 3, 4, 5, 6, 7],
            selected: [
                { value: 1, display: 'Brand' },
                { value: 3, display: 'Dealer Website' },
                { value: 4, display: 'Marketing' },
                { value: 5, display: 'Event' },
                { value: 7, display: 'Digital Retailing' }
            ],
            removable: false,
            lockable: true,
            locked: false
        },
        'vehicle-make': {
            type: 'vehicle-make',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-class': {
            type: 'vehicle-class',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-model': {
            type: 'vehicle-model',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'website-provider': {
            type: 'website-provider',
            available: [2, 3, 4, 5, 42, 43],
            selected: [
                { value: 2, display: 'Dealer Inspire' },
                { value: 3, display: 'DealerON' },
                { value: 4, display: 'Dealer.com' },
                { value: 5, display: 'Dealer eProcess' },
                { value: 43, display: 'Team Velocity' },
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'channel': {
            type: 'channel',
            available: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 12],
            selected: [
                { value: 2, display: 'Paid Search' },
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'digad-channel': {
            type: 'digad-channel',
            selected: [
                { value: '2', display: 'Paid Search' },
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'digad-provider': {
            type: 'digad-provider',
            available: [2, 3, 4, 5, 7, 9, 43, 44, 45, 46, 47, 48, 50, 51, 52],
            selected: [
                { value: 2, display: 'Dealer Inspire' },
                { value: 3, display: 'DealerON' },
                { value: 4, display: 'Dealer.com' },
                { value: 5, display: 'Dealer eProcess' },
                { value: 7, display: 'ZeroSum' },
                { value: 9, display: 'Stream' },
                { value: 43, display: 'Team Velocity' },
                { value: 44, display: 'PureCars' },
                { value: 45, display: 'L2T Media' },
                { value: 46, display: 'C-4 Analytics' },
                { value: 47, display: 'Dealer Teamwork' },
                { value: 48, display: 'Force Marketing' },
                { value: 50, display: 'Fullpath' },
                { value: 51, display: 'Click Here Digital' },
                { value: 52, display: 'Greenline' },
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'digital-retailing-provider': {
            type: 'digital-retailing-provider',
            available: [4, 12, 17, 34, 36, 37, 39, 40, 41],
            selected: [
                { value: 4, display: 'Dealer.com' },
                { value: 12, display: 'CarNow' },
                { value: 17, display: 'Gubagoo' },
                { value: 34, display: 'WebBuy' },
                { value: 36, display: 'AutoFi' },
                { value: 37, display: 'Darwin Automotive' },
                { value: 39, display: 'Motoinsight' },
                { value: 40, display: 'Upstart' },
                { value: 41, display: 'Roadster' },
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'trade-in-provider': {
            type: 'trade-in-provider',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'campaign-type': {
            type: 'campaign-type',
            available: [],
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'profit-center': {
            type: 'profit-center',
            selected: [
                { value: "1", display: 'New Sales' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
    };
}
