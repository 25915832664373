import {
  IDefaultTableColumnConfig,
  ITradeInOverviewBillboardConfig,
  ITradeInOverviewConfig,
  IDualAxisLineGraphConfig,

  IMultiLevelDualAxisLineGraphConfig
} from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';
import { Enums } from '../../../../_shared/enums/enums';

export class TradeInOverviewConfig implements ITradeInOverviewConfig {
  public reportTitle = 'Trade-In Overview';
  public chartSeriesColors = ['#343a40', '#a1a1a1', '#666666'];
  public dealerSummaryLabel = 'Retailer Summary';
  public metricDisplayModes = [
    { name: 'MOM', value: 'MOM' },
    { name: 'YOY', value: 'YOY' }
  ];

  public billboards: ITradeInOverviewBillboardConfig[] = [
    {
      title: 'Trade-Ins Started',
      subtitle: '',
      iconClass: 'fa-users fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'starts',
      metricMOMPropertyName: 'startsMOM',
      metricYOYPropertyName: 'startsYOY',
      metricPreviousMonthPropertyName: 'startsPreviousMonth',
      metricPreviousMTDPropertyName: 'startsPreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'startsMOM',
      metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
      helpTextKey: 'TradeInOverviewTradeInsStartedBillboardHelp',
      helpTextTitle: 'Trade-Ins Started',
    }, {
      title: 'Completed Trade-In Rate',
      subtitle: '',
      iconClass: 'fa-check-square-o fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'completionRate',
      metricMOMPropertyName: 'completionRateMOM',
      metricYOYPropertyName: 'completionRateYOY',
      metricPreviousMonthPropertyName: 'completionRatePreviousMonth',
      metricPreviousMTDPropertyName: 'completionRatePreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'completionRateMOM',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      helpTextKey: 'TradeInOverviewCompletedTradeInRateBillboardHelp',
      helpTextTitle: 'Completed Trade-In Rate',
    }, {
      title: 'Trade-In Leads',
      subtitle: '',
      iconClass: 'fa-calculator fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'leadVolume',
      metricMOMPropertyName: 'leadVolumeMOM',
      metricYOYPropertyName: 'leadVolumeYOY',
      metricPreviousMonthPropertyName: 'leadVolumePreviousMonth',
      metricPreviousMTDPropertyName: 'leadVolumePreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'leadVolumeMOM',
      metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
      helpTextKey: 'TradeInOverviewTradeInLeadsBillboardHelp',
      helpTextTitle: 'Trade-In Leads',
    }, {
      title: 'Trade-In Lead Conversion',
      subtitle: '',
      iconClass: 'fa-user-circle fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'conversionRate',
      metricMOMPropertyName: 'conversionRateMOM',
      metricYOYPropertyName: 'conversionRateYOY',
      metricPreviousMonthPropertyName: 'conversionRatePreviousMonth',
      metricPreviousMTDPropertyName: 'conversionRatePreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      metricPreviousYearPropertyName: null,
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'conversionRateMOM',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      helpTextKey: 'TradeInOverviewTradeInLeadConversionBillboardHelp',
      helpTextTitle: 'Trade-In Lead Conversion Rate',
    },
  ];
  public tradeInKpiSheetOptions = {
    overview: [
      {
        name: 'tradeInMake',
        label: 'Trade-In Make',
        isHeader: true
      },
      {
        name: 'ascent',
        label: 'Ascent',
      },
      {
        name: 'crosstrek',
        label: 'Crosstrek',
      },
      {
        name: 'forester',
        label: 'Forester',
      },
      {
        name: 'impreza',
        label: 'Impreza',
      },
      {
        name: 'legacy',
        label: 'Legacy',
      },
      {
        name: 'outback',
        label: 'Outback',
      },
      {
        name: 'other',
        label: 'Other',
      },
    ]
  };
  public kpiSheetOptions = {
    overview: [{
      name: 'providerId',
      label: 'Provider',
      isHeader: true
    }, 
    {
      name: 'visitsWithImpressions',
      label: 'Visits With Impressions',
    }, 
    {
      name: 'starts',
      label: 'Trade-In Starts',
      format: constants.formatKeys.localeStringOrNa
    }, 
    {
      name: 'startRate',
      label: 'Trade-In Start Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, 
    {
      name: 'appointments',
      label: 'Appointments Set',
      format: constants.formatKeys.localeStringOrNa
    }, 
    {
      name: 'appointmentRate',
      label: 'Appointment Set Rate',
      format: constants.formatKeys.percentageTwoDecimals
    },
    {
      name: 'completions',
      label: 'Completions',
      format: constants.formatKeys.localeStringOrNa
    }, 
    {
      name: 'completionRate',
      label: 'Completed Trade-In Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }]
  };
  public trendGraphConfig: IMultiLevelDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    parentMetrics: [
      {
        id: Enums.tradeInMetrics.impressions.metricId,
        displayName: Enums.tradeInMetrics.impressions.name,
        propertyName: Enums.tradeInMetrics.impressions.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.tradeInMetrics.visitsWithImpressions.metricId,
        displayName: Enums.tradeInMetrics.visitsWithImpressions.name,
        propertyName: Enums.tradeInMetrics.visitsWithImpressions.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.tradeInMetrics.starts.metricId,
        displayName: Enums.tradeInMetrics.starts.name,
        propertyName: Enums.tradeInMetrics.starts.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.tradeInMetrics.startRate.metricId,
        displayName: Enums.tradeInMetrics.startRate.name,
        propertyName: Enums.tradeInMetrics.startRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.tradeInMetrics.appointments.metricId,
        displayName: Enums.tradeInMetrics.appointments.name,
        propertyName: Enums.tradeInMetrics.appointments.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.tradeInMetrics.appointmentRate.metricId,
        displayName: Enums.tradeInMetrics.appointmentRate.name,
        propertyName: Enums.tradeInMetrics.appointmentRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.tradeInMetrics.completions.metricId,
        displayName: Enums.tradeInMetrics.completions.name,
        propertyName: Enums.tradeInMetrics.completions.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.tradeInMetrics.completionRate.metricId,
        displayName: Enums.tradeInMetrics.completionRate.name,
        propertyName: Enums.tradeInMetrics.completionRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.tradeInMetrics.leadVolumn.metricId,
        displayName: Enums.tradeInMetrics.leadVolumn.name,
        propertyName: Enums.tradeInMetrics.leadVolumn.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },

      {
        id: Enums.tradeInMetrics.conversionRate.metricId,
        displayName: Enums.tradeInMetrics.conversionRate.name,
        propertyName: Enums.tradeInMetrics.conversionRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },

    ],
    childMetrics: [
      {
        id: Enums.chatProviders.allProviders.providerId,
        displayName: Enums.chatProviders.allProviders.displayName,
        propertyName: Enums.chatProviders.allProviders.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 33,
        displayName: 'AccuTrade',
        propertyName: 'AccuTrade',
        metricFormatKey: constants.formatKeys.entityDisplayName,
      },
      {
        id: 31,
        displayName: 'Car and Driver',
        propertyName: 'Car and Driver',
        metricFormatKey: constants.formatKeys.entityDisplayName,
      },
      {
        id: 20,
        displayName: 'Edmunds',
        propertyName: 'Edmunds',
        metricFormatKey: constants.formatKeys.entityDisplayName,
      },
      {
        id: 32,
        displayName: 'Kelly Blue Book',
        propertyName: 'Kelly Blue Book',
        metricFormatKey: constants.formatKeys.entityDisplayName,
      },
      {
        id: 33,
        displayName: 'TrueCar',
        propertyName: 'TrueCar',
        metricFormatKey: constants.formatKeys.entityDisplayName,
      },
      {
        id: 35,
        displayName: 'TradeIn Valet',
        propertyName: 'TradeIn Valet',
        metricFormatKey: constants.formatKeys.entityDisplayName,
      },
      {
        id: 14,
        displayName: 'TradePending',
        propertyName: 'TradePending',
        metricFormatKey: constants.formatKeys.entityDisplayName,
      },
      {
        id: 34,
        displayName: 'WebBuy',
        propertyName: 'WebBuy',
        metricFormatKey: constants.formatKeys.entityDisplayName,
      },
    ],
    defaultChildrenIds: [Enums.chatProviders.allProviders.providerId, Enums.chatProviders.allProviders.providerId],
    defaultParentIds: [Enums.tradeInMetrics.visitsWithImpressions.metricId, Enums.tradeInMetrics.startRate.metricId],
    metricOneColorHexCode: '#08a5da',
    metricTwoColorHexCode: '#000000'
  };
  public dealerTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
      // canDrillthrough: (row) => this.helperService.canDrillthrough(this.filterModel, row.entityType)
    }, {
      show: false,
      print: true,
      header: 'Retailer Name',
      columnDef: 'displayName',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
    },

    {
      show: true,
      header: 'Impressions',
      columnDef: 'impressions',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Impressions Prev. Month',
      columnDef: 'impressionsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Impressions MOM',
      columnDef: 'impressionsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    }, {
      show: false,
      header: 'Impressions Prev. Year',
      columnDef: 'impressionsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Impressions YOY',
      columnDef: 'impressionsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Visits With Trade-In Impressions',
      columnDef: 'visitsWithImpressions',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Visits With Trade-In Impressions Prev. Month',
      columnDef: 'visitsWithImpressionsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Visits With Trade-In Impressions MOM',
      columnDef: 'visitsWithImpressionsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    }, {
      show: false,
      header: 'Visits With Trade-In Impressions Prev. Year',
      columnDef: 'visitsWithImpressionsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Visits With Trade-In Impressions YOY',
      columnDef: 'visitsWithImpressionsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Trade-Ins Starts',
      columnDef: 'starts',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Trade-Ins Starts Prev. Month',
      columnDef: 'startsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Trade-Ins Starts MOM',
      columnDef: 'startsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    }, {
      show: false,
      header: 'Trade-Ins Starts Prev. Year',
      columnDef: 'startsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Trade-Ins Starts YOY',
      columnDef: 'startsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Trade-In Start Rate',
      columnDef: 'startRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Trade-In Start Rate Prev. Month',
      columnDef: 'startRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Trade-In Start Rate MOM',
      columnDef: 'startRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    {
      show: false,
      header: 'Trade-In Start Rate Prev. Year',
      columnDef: 'startRatePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Trade-In Start Rate YOY',
      columnDef: 'startRateYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    // appointments
    {
      show: true,
      header: 'Appointments Set',
      columnDef: 'appointments',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Appointments Set Prev. Month',
      columnDef: 'appointmentsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Appointments Set MOM',
      columnDef: 'appointmentsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    }, {
      show: false,
      header: 'Appointments Set Prev. Year',
      columnDef: 'appointmentsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Appointments Set YOY',
      columnDef: 'appointmentsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Appointments Set Rate',
      columnDef: 'appointmentRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Appointments Set Rate Prev. Month',
      columnDef: 'appointmentRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Appointments Set Rate MOM',
      columnDef: 'appointmentRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    {
      show: false,
      header: 'Appointments Set Rate Prev. Year',
      columnDef: 'appointmentRatePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Appointments Set Rate YOY',
      columnDef: 'appointmentRateYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
     // completions
     {
      show: true,
      header: 'Completions',
      columnDef: 'completions',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Completions Prev. Month',
      columnDef: 'completionsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Completions MOM',
      columnDef: 'completionsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    }, {
      show: false,
      header: 'Completions Prev. Year',
      columnDef: 'completionsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    }, {
      show: false,
      header: 'Completions YOY',
      columnDef: 'completionsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },

    {
      show: true,
      header: 'Completed Trade-In Rate',
      columnDef: 'completionRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Completed Trade-In Rate Prev. Month',
      columnDef: 'completionRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Completed Trade-In Rate MOM',
      columnDef: 'completionRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    {
      show: false,
      header: 'Completed Trade-In Rate Prev. Year',
      columnDef: 'completionRatePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Completed Trade-In Rate YOY',
      columnDef: 'completionRateYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // Trade-In Leads
    {
      show: true,
      header: 'Trade-In Leads',
      columnDef: 'leadVolume',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    {
      show: false,
      header: 'Trade-In Leads Prev. Month',
      columnDef: 'leadVolumePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    {
      show: false,
      header: 'Trade-In Leads MOM',
      columnDef: 'leadVolumeMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    {
      show: false,
      header: 'Trade-In Leads Prev. Year',
      columnDef: 'leadVolumePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false
    },
    {
      show: false,
      header: 'Trade-In Leads YOY',
      columnDef: 'leadVolumeYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    // Trade-In Lead Conversion
    {
      show: true,
      header: 'Trade-In Lead Conversion Rate',
      columnDef: 'conversionRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Trade-In Lead Conversion  Rate Prev. Month',
      columnDef: 'conversionRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Trade-In Lead Conversion  Rate MOM',
      columnDef: 'conversionRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
    {
      show: false,
      header: 'Trade-In Lead Conversion  Rate Prev. Year',
      columnDef: 'conversionRatePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    {
      show: false,
      header: 'Trade-In Lead Conversion  Rate YOY',
      columnDef: 'conversionRateYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageOneDecimal,
      clickable: false
    },
  ];
}
