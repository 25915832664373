import { IDealerResourceCategoryConfig, IDealerResourcesConfig } from '../../../../_shared/models/models-index';

export class DealerResourcesConfig implements IDealerResourcesConfig {
    public reportTitle = 'Marketing Resources';
    public disclaimer = 'Please click the download icon on the bottom right of each scorecard to access the report';
    public resourceCategories: IDealerResourceCategoryConfig[] = [
        {
            category: 'Provider Scorecards',
            resourceConfig: [
                {
                    label: 'Chat/Text',
                    imgUrl: 'https://subaruusv2prod.blob.core.windows.net/analytics-resources/Subaru_Thumb_Chat.png',
                    downloadUrl: 'https://subaruusv2prod.blob.core.windows.net/analytics-resources/ProviderScorecard_Chat_Q12023.pdf'
                },
                {
                    label: 'Trade-In',
                    imgUrl: 'https://subaruusv2prod.blob.core.windows.net/analytics-resources/Subaru_Thumb_Trade-In.png',
                    downloadUrl: 'https://subaruusv2prod.blob.core.windows.net/analytics-resources/ProviderScorecard_Trade_Q12023.pdf'
                },
                {
                    label: 'Digital Retailing',
                    imgUrl: 'https://subaruusv2prod.blob.core.windows.net/analytics-resources/Subaru_Thumb_Digital_Retailing.png',
                    downloadUrl: 'https://subaruusv2prod.blob.core.windows.net/analytics-resources/ProviderScorecard_DR_Q12023.pdf'
                },
            ]
        }
    ];
}
