import {
   IDefaultTableColumnConfig,
   IMultiLevelDualAxisLineGraphConfig,
   IWebsiteReferrerConfig,
   ICardHeaderConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class WebsiteReferrerConfig implements IWebsiteReferrerConfig {
   reportTitle = 'Referrer Report';
   public useDefaultComparisonColumns: boolean = true;

   public benchmarkCardHeader: ICardHeaderConfig = {
      title: 'Summary Data',
      iconClass: 'fa-file',
      helpTextKey: constants.helpTextKeys.websiteReferrerSummaryHelp,
      helpTextTitle: 'Referrer Summary',
      exportName: 'Referrer Report - Summary Data',
      metricDisplayModes: ['MOM', 'YOY']
   };

    public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        parentMetrics: [
          // Visits
          {
            id: Enums.websiteMetrics.totalVisits.metricId,
            displayName: 'Website Visits',
            propertyName: Enums.websiteMetrics.totalVisits.nameKey,
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            id: Enums.websiteMetrics.uniqueVisits.metricId,
            displayName: 'Unique Visitors',
            propertyName: Enums.websiteMetrics.uniqueVisits.nameKey,
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            id: Enums.websiteMetrics.pageViews.metricId,
            displayName: 'Total Page Views',
            propertyName: Enums.websiteMetrics.pageViews.nameKey,
            metricFormatKey: constants.formatKeys.localeString
          },
          // Avg. Time on Site
          {
            id: Enums.websiteMetrics.avgTimeOnSite.metricId,
            displayName: 'Avg Time on Site',
            propertyName: Enums.websiteMetrics.avgTimeOnSite.nameKey,
            metricFormatKey: constants.formatKeys.localeString
          },
          {
              id: Enums.websiteMetrics.engagements.metricId,
              displayName: Enums.websiteMetrics.engagements.name,
              propertyName: Enums.websiteMetrics.engagements.nameKey,
              metricFormatKey: constants.formatKeys.localeString
          },
          {
              id: Enums.websiteMetrics.engagementRate.metricId,
              displayName: Enums.websiteMetrics.engagementRate.name,
              propertyName: Enums.websiteMetrics.engagementRate.nameKey,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals
          },
          {
              id: Enums.websiteMetrics.actions.metricId,
              displayName: Enums.websiteMetrics.actions.name,
              propertyName: Enums.websiteMetrics.actions.nameKey,
              metricFormatKey: constants.formatKeys.localeString
          },
          {
              id: Enums.websiteMetrics.actionRate.metricId,
              displayName: Enums.websiteMetrics.actionRate.name,
              propertyName: Enums.websiteMetrics.actionRate.nameKey,
              metricFormatKey: constants.formatKeys.percentageTwoDecimals
          },
          // Forms
          {
            id: Enums.websiteMetrics.formSubmissions.metricId,
            displayName: Enums.websiteMetrics.formSubmissions.name,
            propertyName: Enums.websiteMetrics.formSubmissions.nameKey,
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            id: Enums.websiteMetrics.formConversionRate.metricId,
            displayName: Enums.websiteMetrics.formConversionRate.name,
            propertyName: Enums.websiteMetrics.formConversionRate.nameKey,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
          },
          // Click to Call
          {
            id: Enums.websiteMetrics.clickToCalls.metricId,
            displayName: Enums.websiteMetrics.clickToCalls.name,
            propertyName: Enums.websiteMetrics.clickToCalls.nameKey,
            metricFormatKey: constants.formatKeys.localeString
          },
          // Bounces
          {
            id: Enums.websiteMetrics.bounces.metricId,
            displayName: Enums.websiteMetrics.bounces.name,
            propertyName: Enums.websiteMetrics.bounces.nameKey,
            metricFormatKey: constants.formatKeys.localeString
          },
          // Bounce Rate
          {
            id: Enums.websiteMetrics.bounceRate.metricId,
            displayName: Enums.websiteMetrics.bounceRate.name,
            propertyName: Enums.websiteMetrics.bounceRate.nameKey,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals
          },
          // VDPs
          {
            id: Enums.websiteMetrics.newVlpViews.metricId,
            displayName: Enums.websiteMetrics.newVlpViews.name,
            propertyName: 'vlpNewViews',
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            id: Enums.websiteMetrics.newInventoryDetailViews.metricId,
            displayName: Enums.websiteMetrics.newVdpViews.name,
            propertyName: 'vdpNewViews',
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            id: Enums.websiteMetrics.usedVlpViews.metricId,
            displayName: Enums.websiteMetrics.usedVlpViews.name,
            propertyName: 'vlpUsedViews',
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            id: Enums.websiteMetrics.usedInventoryDetailViews.metricId,
            displayName: Enums.websiteMetrics.usedVdpViews.name,
            propertyName: 'vdpUsedViews',
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            id: Enums.websiteMetrics.mixedVlpViews.metricId,
            displayName: Enums.websiteMetrics.mixedVlpViews.name,
            propertyName: 'vlpMixedViews',
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            id: Enums.websiteMetrics.cpoInventoryDetailViews.metricId,
            displayName: Enums.websiteMetrics.cpoVdpViews.name,
            propertyName: 'vdpCPOViews',
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            id: Enums.websiteMetrics.totalVlpViews.metricId,
            displayName: Enums.websiteMetrics.totalVlpViews.name,
            propertyName: 'vlpTotalViews',
            metricFormatKey: constants.formatKeys.localeString
          },
          {
            id: Enums.websiteMetrics.totalVdpViews.metricId,
            displayName: Enums.websiteMetrics.totalVdpViews.name,
            propertyName: 'vdpTotalViews',
            metricFormatKey: constants.formatKeys.localeString
          }
        ],
        childMetrics: [
            {
               id: Enums.channels.allChannels.channelId,
               displayName: Enums.channels.allChannels.channelName,
               propertyName: Enums.channels.allChannels.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.brand.channelId,
               displayName: Enums.channels.brand.channelName,
               propertyName: Enums.channels.brand.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.organicEmail.channelId,
               displayName: Enums.channels.organicEmail.channelName,
               propertyName: Enums.channels.organicEmail.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.organicSearch.channelId,
               displayName: Enums.channels.organicSearch.channelName,
               propertyName: Enums.channels.organicSearch.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.organicSocial.channelId,
               displayName: Enums.channels.organicSocial.channelName,
               propertyName: Enums.channels.organicSocial.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.paidDisplay.channelId,
               displayName: Enums.channels.paidDisplay.channelName,
               propertyName: Enums.channels.paidDisplay.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.paidEmail.channelId,
               displayName: Enums.channels.paidEmail.channelName,
               propertyName: Enums.channels.paidEmail.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.paidSearch.channelId,
               displayName: Enums.channels.paidSearch.channelName,
               propertyName: Enums.channels.paidSearch.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.paidSocial.channelId,
               displayName: Enums.channels.paidSocial.channelName,
               propertyName: Enums.channels.paidSocial.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.paidVideo.channelId,
               displayName: Enums.channels.paidVideo.channelName,
               propertyName: Enums.channels.paidVideo.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.thirdPartySites.channelId,
               displayName: Enums.channels.thirdPartySites.channelName,
               propertyName: Enums.channels.thirdPartySites.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.typedBookmarked.channelId,
               displayName: Enums.channels.typedBookmarked.channelName,
               propertyName: Enums.channels.typedBookmarked.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: Enums.channels.other.channelId,
               displayName: Enums.channels.other.channelName,
               propertyName: Enums.channels.other.channelName,
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
            {
               id: 13,
               displayName: 'none',
               propertyName: 'None',
               metricFormatKey: constants.formatKeys.entityDisplayName
            },
        ],

        defaultChildrenIds: [ Enums.channels.allChannels.channelId, Enums.channels.allChannels.channelId ],
        defaultParentIds: [ Enums.websiteMetrics.totalVisits.metricId, Enums.websiteMetrics.uniqueVisits.metricId ],
        metricOneColorHexCode: '#08a5da',
        metricTwoColorHexCode: '#000000'
    };

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: 'Referring Channel',
            columnDef: 'entity',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        // Visits
        {
          show: true,
          header: 'Website Visits',
          columnDef: 'totalVisits',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
        // Unique Visits
        {
          show: true,
          header: 'Unique Visitors',
          columnDef: 'uniqueVisits',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
        // Total Page Views
        {
          show: true,
          header: 'Total Page Views',
          columnDef: 'pageViews',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
        // Avg Time on Site
        {
          show: true,
          header: 'Avg Time On Site',
          columnDef: 'averageTimeOnSite',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds
        },
        // Engagements
        {
          show: true,
          header: 'Engagements',
          columnDef: 'engagements',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
        // Engagement Rate
        {
          show: true,
          header: 'Engagement Rate',
          columnDef: 'engagementRate',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        // Actions
        {
          show: true,
          header: 'Actions',
          columnDef: 'actions',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
        // Action Rate
        {
          show: true,
          header: 'Action Rate',
          columnDef: 'actionRate',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        // Form Submissions
        {
          show: true,
          header: 'Form Submissions',
          columnDef: 'formSubmissions',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },

        // Form Conversion Rate
        {
          show: true,
          header: 'Form Conversion Rate',
          columnDef: 'formConversionRate',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        // Click to Call
        {
          show: true,
          header: 'Click to Call',
          columnDef: 'clickToCalls',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
        // Bounces
        {
          show: true,
          header: 'Bounces',
          columnDef: 'bounces',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
        // Bounce Rate
        {
          show: true,
          header: 'Bounce Rate',
          columnDef: 'bounceRate',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.percentageTwoDecimals
        },
        // New VLP
        {
          show: true,
          header: 'New VLP Views',
          columnDef: 'vlpNewViews',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
        // NEW VDPs
        {
          show: true,
          header: 'New VDP Views',
          columnDef: 'vdpNewViews',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
        // Used VLPs
        {
          show: true,
          header: 'Used VLP Views',
          columnDef: 'vlpUsedViews',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
        // Used VDPs
        {
          show: true,
          header: 'Used VDP Views',
          columnDef: 'vdpUsedViews',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
        // Mixed Vlps
        {
          show: true,
          header: 'Mixed VLP Views',
          columnDef: 'vlpMixedViews',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
        // CPO VDPs
        {
          show: true,
          header: 'CPO VDP Views',
          columnDef: 'vdpCPOViews',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
        // Total Vlps
        {
          show: true,
          header: 'Total VLP Views',
          columnDef: 'vlpTotalViews',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        },
        // Total VDPs
        {
          show: true,
          header: 'Total VDP Views',
          columnDef: 'vdpTotalViews',
          hasComparisonMetrics: true,
          metricFormatKey: constants.formatKeys.localeString
        }
      ];
}
