import {
  IWebsiteScorecardConfig, IWebsiteScorecardCategoryConfig
} from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';

export class WebsiteScorecardConfig implements IWebsiteScorecardConfig {
  public reportTitle = 'Website Scorecard';
  public scorecards: IWebsiteScorecardCategoryConfig[] = [
    {
      category: 'Volume',
      billboards: [
        {
          title: 'Visits',
          subtitle: '',
          subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          iconClass: 'fa-users fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'totalVisits',
          metricMOMPropertyName: 'totalVisitsMOM',
          metricYOYPropertyName: 'totalVisitsYOY',
          metricPreviousMonthPropertyName: 'totalVisitsPreviousMonth',
          metricPreviousMTDPropertyName: 'totalVisitsPreviousMTD',
          metricPreviousYearPropertyName: 'totalVisitsPreviousYear',
          footerLeftLabel: 'MOM',
          footerRightLabel: 'YOY',
          footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          trendArrowDrivingPropertyName: 'totalVisitsMOM',
          metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          helpTextKey: 'WebsiteScorecardTotalVisitsHelp',
          helpTextTitle: 'Visits',
          scoreCardCategory: 'Volume'
        },
        {
          title: 'Unique Visitors',
          subtitle: '',
          subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          iconClass: 'fa-user fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'uniqueVisits',
          metricMOMPropertyName: 'uniqueVisitsMOM',
          metricYOYPropertyName: 'uniqueVisitsYOY',
          metricPreviousMonthPropertyName: 'uniqueVisitsPreviousMonth',
          metricPreviousMTDPropertyName: 'uniqueVisitsPreviousMTD',
          metricPreviousYearPropertyName: 'uniqueVisitsPreviousYear',
          footerLeftLabel: 'MOM',
          footerRightLabel: 'YOY',
          footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          trendArrowDrivingPropertyName: 'uniqueVisitsMOM',
          metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          helpTextKey: 'WebsiteScorecardUniqueVisitsHelp',
          helpTextTitle: 'Unique Visits',
          scoreCardCategory: 'Volume'
        },
        {
          title: 'Page Views',
          subtitle: '',
          subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          iconClass: 'fa-file fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'pageViews',
          metricMOMPropertyName: 'pageViewsMOM',
          metricYOYPropertyName: 'pageViewsYOY',
          metricPreviousMonthPropertyName: 'pageViewsPreviousMonth',
          metricPreviousMTDPropertyName: 'pageViewsPreviousMTD',
          metricPreviousYearPropertyName: 'pageViewsPreviousYear',
          footerLeftLabel: 'MOM',
          footerRightLabel: 'YOY',
          footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          trendArrowDrivingPropertyName: 'pageViewsMOM',
          metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          helpTextKey: 'WebsiteScorecardPageViewsHelp',
          helpTextTitle: 'Page Views',
          scoreCardCategory: 'Volume'
        }
      ],
    },
    {
      category: 'Engagements',
      billboards: [
        {
          title: 'Engagement Rate',
          subtitle: '',
          subtitleMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          iconClass: 'fa-user-plus fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'engagementRate',
          metricMOMPropertyName: 'engagementRateMOM',
          metricYOYPropertyName: 'engagementRateYOY',
          metricPreviousMonthPropertyName: 'engagementRatePreviousMonth',
          metricPreviousMTDPropertyName: 'engagementRatePreviousMTD',
          metricPreviousYearPropertyName: 'engagementRatePreviousYear',
          footerLeftLabel: 'MOM',
          footerRightLabel: 'YOY',
          footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          trendArrowDrivingPropertyName: 'engagementRateMOM',
          metricFormatKey: constants.formatKeys.percentageTwoDecimals,
          helpTextKey: 'WebsiteScorecardEngagementRateHelp',
          helpTextTitle: 'Engagement Rate',
          scoreCardCategory: 'Engagements'
        },
        {
          title: 'Avg Time On Site',
          subtitle: '',
          subtitleMetricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds,
          iconClass: 'fa-clock-o fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'averageTimeOnSite',
          metricMOMPropertyName: 'averageTimeOnSiteMOM',
          metricYOYPropertyName: 'averageTimeOnSiteYOY',
          metricPreviousMonthPropertyName: 'averageTimeOnSitePreviousMonth',
          metricPreviousMTDPropertyName: 'averageTimeOnSitePreviousMTD',
          metricPreviousYearPropertyName: 'averageTimeOnSitePreviousYear',
          footerLeftLabel: 'MOM',
          footerRightLabel: 'YOY',
          footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          trendArrowDrivingPropertyName: 'averageTimeOnSiteMOM',
          metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds,
          helpTextKey: 'WebsiteScorecardTimeOnSiteHelp',
          helpTextTitle: 'Avg Time on Site',
          scoreCardCategory: 'Engagements'
        },
        {
          title: 'VDP Views',
          subtitle: '',
          subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          iconClass: 'fa-file fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'vdpTotalViews',
          metricMOMPropertyName: 'vdpTotalViewsMOM',
          metricYOYPropertyName: 'vdpTotalViewsYOY',
          metricPreviousMonthPropertyName: 'vdpTotalViewsPreviousMonth',
          metricPreviousMTDPropertyName: 'vdpTotalViewsPreviousMTD',
          metricPreviousYearPropertyName: 'vdpTotalViewsPreviousYear',
          footerLeftLabel: 'MOM',
          footerRightLabel: 'YOY',
          footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          trendArrowDrivingPropertyName: 'vdpTotalViewsMOM',
          metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          helpTextKey: 'WebsiteScorecardVDPViewsHelp',
          helpTextTitle: 'VDP Views',
          scoreCardCategory: 'Engagements'
        },
        {
          title: 'Bounces',
          subtitle: '',
          subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          iconClass: 'fa-percent fa-primary',
          reverseMetric: true,
          metricCurrentPropertyName: 'bounces',
          metricMOMPropertyName: 'bouncesMOM',
          metricYOYPropertyName: 'bouncesYOY',
          metricPreviousMonthPropertyName: 'bouncesPreviousMonth',
          metricPreviousMTDPropertyName: 'bouncesPreviousMTD',
          metricPreviousYearPropertyName: 'bouncesPreviousYear',
          footerLeftLabel: 'MOM',
          footerRightLabel: 'YOY',
          footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          trendArrowDrivingPropertyName: 'bouncesMOM',
          metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          helpTextKey: 'WebsiteScorecardBouncesHelp',
          helpTextTitle: 'Bounces',
          scoreCardCategory: 'Engagements'
        },
        {
          title: 'Bounce Rate',
          subtitle: '',
          subtitleMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          iconClass: 'fa-percent fa-primary',
          reverseMetric: true,
          metricCurrentPropertyName: 'bounceRate',
          metricMOMPropertyName: 'bounceRateMOM',
          metricYOYPropertyName: 'bounceRateYOY',
          metricPreviousMonthPropertyName: 'bounceRatePreviousMonth',
          metricPreviousMTDPropertyName: 'bounceRatePreviousMTD',
          metricPreviousYearPropertyName: 'bounceRatePreviousYear',
          footerLeftLabel: 'MOM',
          footerRightLabel: 'YOY',
          footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          trendArrowDrivingPropertyName: 'bounceRateMOM',
          metricFormatKey: constants.formatKeys.percentageTwoDecimals,
          helpTextKey: 'WebsiteScorecardBounceRateHelp',
          helpTextTitle: 'Bounce Rate',
          scoreCardCategory: 'Engagements'
        },
      ]
    },
    {
      category: 'Actions',
      billboards: [
        {
          title: 'Action Rate',
          subtitle: '',
          subtitleMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          iconClass: 'fa-percent fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'actionRate',
          metricMOMPropertyName: 'actionRateMOM',
          metricYOYPropertyName: 'actionRateYOY',
          metricPreviousMonthPropertyName: 'actionRatePreviousMonth',
          metricPreviousMTDPropertyName: 'actionRatePreviousMTD',
          metricPreviousYearPropertyName: 'actionRatePreviousYear',
          footerLeftLabel: 'MOM',
          footerRightLabel: 'YOY',
          footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          trendArrowDrivingPropertyName: 'actionRateMOM',
          metricFormatKey: constants.formatKeys.percentageTwoDecimals,
          helpTextKey: 'WebsiteScorecardActionRateHelp',
          helpTextTitle: 'Action Rate',
          scoreCardCategory: 'Actions'
        },
        {
          title: 'Form Submissions',
          subtitle: '',
          subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          iconClass: 'fa-file-text fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'formSubmissions',
          metricMOMPropertyName: 'formSubmissionsMOM',
          metricYOYPropertyName: 'formSubmissionsYOY',
          metricPreviousMonthPropertyName: 'formSubmissionsPreviousMonth',
          metricPreviousMTDPropertyName: 'formSubmissionsPreviousMTD',
          metricPreviousYearPropertyName: 'formSubmissionsPreviousYear',
          footerLeftLabel: 'MOM',
          footerRightLabel: 'YOY',
          footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          trendArrowDrivingPropertyName: 'formSubmissionsMOM',
          metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          helpTextKey: 'WebsiteScorecardFormSubmissionsHelp',
          helpTextTitle: 'Form Submissions',
          scoreCardCategory: 'Actions'
        },
        {
          title: 'Click to Calls',
          subtitle: '',
          subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          iconClass: 'fa-phone fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'clickToCalls',
          metricMOMPropertyName: 'clickToCallsMOM',
          metricYOYPropertyName: 'clickToCallsYOY',
          metricPreviousMonthPropertyName: 'clickToCallsPreviousMonth',
          metricPreviousMTDPropertyName: 'clickToCallsPreviousMTD',
          metricPreviousYearPropertyName: 'clickToCallsPreviousYear',
          footerLeftLabel: 'MOM',
          footerRightLabel: 'YOY',
          footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          trendArrowDrivingPropertyName: 'clickToCallsMOM',
          metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          helpTextKey: 'WebsiteScorecardClickToCallsHelp',
          helpTextTitle: 'Click to Calls',
          scoreCardCategory: 'Actions'
        },
        {
          title: 'Phone Calls',
          subtitle: '',
          subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          iconClass: 'fa-phone fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'phoneCalls',
          metricMOMPropertyName: 'phoneCallsMOM',
          metricYOYPropertyName: 'phoneCallsYOY',
          metricPreviousMonthPropertyName: 'phoneCallsPreviousMonth',
          metricPreviousMTDPropertyName: 'phoneCallsPreviousMTD',
          metricPreviousYearPropertyName: 'phoneCallsPreviousYear',
          footerLeftLabel: 'MOM',
          footerRightLabel: 'YOY',
          footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          trendArrowDrivingPropertyName: 'phoneCallsMOM',
          metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          helpTextKey: 'WebsiteScorecardPhoneCallsHelp',
          helpTextTitle: 'Phone Calls',
          scoreCardCategory: 'Actions'
        },
      ]
    }
  ];
}
