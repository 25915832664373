import {
  IChatProviderScorecardConfig,
  IChatProviderScorecardBillboardConfig
} from '../../../../_shared/models/models-index';
import { IMetricType, Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';


export class ChatProviderScorecardConfig implements IChatProviderScorecardConfig {
  public reportTitle: string = 'Chat Provider Scorecard';
  public scorecardMetrics: IChatProviderScorecardBillboardConfig[] = [
    {
      metric: Enums.chatMetrics.enrollments,
      metricPosition: 1,
      filterPosition: 1,
      metricFormatKey: constants.formatKeys.localeStringOrNa
    },
    {
      metric: Enums.chatMetrics.chatStartRate,
      metricPosition: 2,
      filterPosition: 2,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa
    },
    {
      metric: Enums.chatMetrics.chatEngagementRate,
      metricPosition: 3,
      filterPosition: 3,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa
    },
    {
      metric: Enums.chatMetrics.chatConversionRate,
      metricPosition: 4,
      filterPosition:  4,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa
    },
  ];
}
