import {
  IDefaultTableColumnConfig,
  IWebsiteProviderConfig,
  IMultiLevelDualAxisLineGraphConfig,
  ICardHeaderConfig
} from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class WebsiteProviderConfig implements IWebsiteProviderConfig {
  public reportTitle = 'Website Provider Performance';
  public useDefaultComparisonColumns: boolean = true;
  public benchmarkCardHeader: ICardHeaderConfig = {
    title: 'Summary Data',
    iconClass: 'fa-file',
    helpTextKey: constants.helpTextKeys.WebsiteProviderSummaryHelp,
    helpTextTitle: 'Provider Summary',
    exportName: 'Website Provider Performance - Summary Data',
    metricDisplayModes: ['MOM', 'YOY']
  };

  public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    parentMetrics: [
      // Visits
      {
        id: Enums.websiteMetrics.totalVisits.metricId,
        displayName: 'Website Visits',
        propertyName: Enums.websiteMetrics.totalVisits.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.uniqueVisits.metricId,
        displayName: 'Unique Visitors',
        propertyName: Enums.websiteMetrics.uniqueVisits.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.pageViews.metricId,
        displayName: 'Total Page Views',
        propertyName: Enums.websiteMetrics.pageViews.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Avg. Time on Site
      {
        id: Enums.websiteMetrics.avgTimeOnSite.metricId,
        displayName: 'Avg Time on Site',
        propertyName: Enums.websiteMetrics.avgTimeOnSite.nameKey,
        metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds
      },
      // Engagements
      {
        id: Enums.websiteMetrics.engagements.metricId,
        displayName: Enums.websiteMetrics.engagements.name,
        propertyName: Enums.websiteMetrics.engagements.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Engagement Rate
      {
        id: Enums.websiteMetrics.engagementRate.metricId,
        displayName: Enums.websiteMetrics.engagementRate.name,
        propertyName: Enums.websiteMetrics.engagementRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Actions
      {
        id: Enums.websiteMetrics.actions.metricId,
        displayName: Enums.websiteMetrics.actions.name,
        propertyName: Enums.websiteMetrics.actions.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Action Rate
      {
        id: Enums.websiteMetrics.actionRate.metricId,
        displayName: Enums.websiteMetrics.actionRate.name,
        propertyName: Enums.websiteMetrics.actionRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Forms
      {
        id: Enums.websiteMetrics.formSubmissions.metricId,
        displayName: Enums.websiteMetrics.formSubmissions.name,
        propertyName: Enums.websiteMetrics.formSubmissions.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.formConversionRate.metricId,
        displayName: Enums.websiteMetrics.formConversionRate.name,
        propertyName: Enums.websiteMetrics.formConversionRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Phone
      {
        id: Enums.websiteMetrics.phoneCalls.metricId,
        displayName: Enums.websiteMetrics.phoneCalls.name,
        propertyName: Enums.websiteMetrics.phoneCalls.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.phoneConversionRate.metricId,
        displayName: Enums.websiteMetrics.phoneConversionRate.name,
        propertyName: Enums.websiteMetrics.phoneConversionRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Total Contact
      {
        id: Enums.websiteMetrics.totalContacts.metricId,
        displayName: Enums.websiteMetrics.totalContacts.name,
        propertyName: Enums.websiteMetrics.totalContacts.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.totalContactRate.metricId,
        displayName: Enums.websiteMetrics.totalContactRate.name,
        propertyName: Enums.websiteMetrics.totalContactRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // Click to Call
      {
        id: Enums.websiteMetrics.clickToCalls.metricId,
        displayName: Enums.websiteMetrics.clickToCalls.name,
        propertyName: Enums.websiteMetrics.clickToCalls.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Bounces
      {
        id: Enums.websiteMetrics.bounces.metricId,
        displayName: Enums.websiteMetrics.bounces.name,
        propertyName: Enums.websiteMetrics.bounces.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Bounce Rate
      {
        id: Enums.websiteMetrics.bounceRate.metricId,
        displayName: Enums.websiteMetrics.bounceRate.name,
        propertyName: Enums.websiteMetrics.bounceRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // VDPs
      {
        id: Enums.websiteMetrics.newVlpViews.metricId,
        displayName: Enums.websiteMetrics.newVlpViews.name,
        propertyName: 'vlpNewViews',
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.newInventoryDetailViews.metricId,
        displayName: Enums.websiteMetrics.newVdpViews.name,
        propertyName: 'vdpNewViews',
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.usedVlpViews.metricId,
        displayName: Enums.websiteMetrics.usedVlpViews.name,
        propertyName: 'vlpUsedViews',
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.usedInventoryDetailViews.metricId,
        displayName: Enums.websiteMetrics.usedVdpViews.name,
        propertyName: 'vdpUsedViews',
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.mixedVlpViews.metricId,
        displayName: Enums.websiteMetrics.mixedVlpViews.name,
        propertyName: 'vlpMixedViews',
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.cpoInventoryDetailViews.metricId,
        displayName: Enums.websiteMetrics.cpoVdpViews.name,
        propertyName: 'vdpCPOViews',
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.totalVlpViews.metricId,
        displayName: Enums.websiteMetrics.totalVlpViews.name,
        propertyName: 'vlpTotalViews',
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.websiteMetrics.totalVdpViews.metricId,
        displayName: Enums.websiteMetrics.totalVdpViews.name,
        propertyName: 'vdpTotalViews',
        metricFormatKey: constants.formatKeys.localeString
      }
    ],
    childMetrics: [
      {
        id: 0,
        displayName: Enums.websiteProviders.allProviders.name,
        propertyName: Enums.websiteProviders.allProviders.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 2,
        displayName:  Enums.websiteProviders.dealerInspire.displayName,
        propertyName: Enums.websiteProviders.dealerInspire.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 3,
        displayName:  Enums.websiteProviders.dealerOn.displayName,
        propertyName: Enums.websiteProviders.dealerOn.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 4,
        displayName:  Enums.websiteProviders.dealerDotCom.displayName,
        propertyName: Enums.websiteProviders.dealerDotCom.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 5,
        displayName:  Enums.websiteProviders.dealerEProcess.displayName,
        propertyName: Enums.websiteProviders.dealerEProcess.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 43,
        displayName:  Enums.websiteProviders.teamVelocity.displayName,
        propertyName: Enums.websiteProviders.teamVelocity.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 42,
        displayName: 'Other',
        propertyName: 'Other',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
    ],

    defaultChildrenIds: [Enums.websiteProviders.allProviders.providerId, Enums.websiteProviders.allProviders.providerId],
    defaultParentIds: [Enums.websiteMetrics.totalVisits.metricId, Enums.websiteMetrics.uniqueVisits.metricId],
    metricOneColorHexCode: '#08a5da',
    metricTwoColorHexCode: '#000000'
  };

  public summaryTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: 'Provider',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
    },
    // Visits
    {
      show: true,
      header: 'Website Visits',
      columnDef: 'totalVisits',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Unique Visits
    {
      show: true,
      header: 'Unique Visitors',
      columnDef: 'uniqueVisits',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Total Page Views
    {
      show: true,
      header: 'Total Page Views',
      columnDef: 'pageViews',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Avg Time on Site
    {
      show: true,
      header: 'Avg Time On Site',
      columnDef: 'averageTimeOnSite',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds
    },

    // Engagements
    {
      show: true,
      header: 'Engagements',
      columnDef: 'engagements',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },

    // Engagement Rate
    {
      show: true,
      header: 'Engagement Rate',
      columnDef: 'engagementRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Actions
    {
      show: true,
      header: 'Actions',
      columnDef: 'actions',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Action Rate
    {
      show: true,
      header: 'Action Rate',
      columnDef: 'actionRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Form Submissions
    {
      show: true,
      header: 'Form Submissions',
      columnDef: 'formSubmissions',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Form Conversion Rate
    {
      show: true,
      header: 'Form Conversion Rate',
      columnDef: 'formConversionRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Phone Calls
    {
      show: true,
      header: 'Phone Calls',
      columnDef: 'phoneCalls',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Phone Conversion Rate
    {
      show: true,
      header: 'Phone Conversion Rate',
      columnDef: 'phoneConversionRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // Total Contacts
    {
      show: true,
      header: 'Total Contacts',
      columnDef: 'totalContacts',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Total Contact Rate
    {
      show: true,
      header: 'Total Contact Rate',
      columnDef: 'totalContactRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },

    // Click to Call
    {
      show: true,
      header: 'Click to Call',
      columnDef: 'clickToCalls',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },

    // Bounces
    {
      show: true,
      header: 'Bounces',
      columnDef: 'bounces',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Bounce Rate
    {
      show: true,
      header: 'Bounce Rate',
      columnDef: 'bounceRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals
    },
    // New VLP
    {
      show: true,
      header: 'New VLP Views',
      columnDef: 'vlpNewViews',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },

    // New VDP
    {
      show: true,
      header: 'New VDP Views',
      columnDef: 'vdpNewViews',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Used VLPs
    {
      show: true,
      header: 'Used VLP Views',
      columnDef: 'vlpUsedViews',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Used VDPs
    {
      show: true,
      header: 'Used VDP Views',
      columnDef: 'vdpUsedViews',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Mixed Vlps
    {
      show: true,
      header: 'Mixed VLP Views',
      columnDef: 'vlpMixedViews',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // CPO VDPs
    {
      show: true,
      header: 'CPO VDP Views',
      columnDef: 'vdpCPOViews',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Total Vlps
    {
      show: true,
      header: 'Total VLP Views',
      columnDef: 'vlpTotalViews',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    },
    // Total VDPs
    {
      show: true,
      header: 'Total VDP Views',
      columnDef: 'vdpTotalViews',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString
    }
  ];

}
