import {
  ITradeInProviderScorecardConfig,
  ITradeInProviderScorecardBillboardConfig
} from '../../../../_shared/models/models-index';
import { IMetricType, Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';


export class TradeInProviderScorecardConfig implements ITradeInProviderScorecardConfig {
  public reportTitle: string = 'Trade-In Provider Scorecard';
  public scorecardMetrics: ITradeInProviderScorecardBillboardConfig[] = [
    {
      metric: Enums.tradeInMetrics.enrollments,
      metricPosition: 1,
      filterPosition: 1,
      metricFormatKey: constants.formatKeys.localeStringOrNa
    },
    {
      metric: Enums.tradeInMetrics.startRate,
      metricPosition: 2,
      filterPosition: 2,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa
    },
    {
      metric: Enums.tradeInMetrics.completionRate,
      metricPosition: 3,
      filterPosition: 3,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa
    },
    {
      metric: Enums.tradeInMetrics.conversionRate,
      metricPosition: 4,
      filterPosition: 4,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa
    },
  ];
}
